import React, { Fragment } from "react";
import Header from '../components/Header'

import '../styles/About.css'
import { Timeline, Event } from "react-timeline-scribble";

function About() {
  return (

    <div class="container">


      <Header />

      <div class="timeline">
        <Fragment >
          <Timeline>
            <Event interval={"EDUCATION"} >
              <p><strong>COMPUTER ENGINEERING | 2023</strong></p>
              <p>Pamukkale University</p>
              Bachelor of Science
              <p>GPA: 3.15</p>

              <p><strong>HIGH SCHOOL DIPLOMA | 2017</strong></p>
              <p>Sami Evkuran Anatolian High School</p>
            </Event>
            <Event interval={"EXPERIENCE"}>
              <br></br>
              <p><strong>SOFTWARE ENGINEER INTERN FEB 2024 - AUG 2024</strong></p>
              <p><strong>TURKCELL 
                <br></br>Atmosware</strong></p>
              <p> Backend Software Engineer</p>
              <ul>
                <li> I have achieved successful implementation of 2PC and SAGA Pattern</li>
                <li> I gave a presentation about Distributed Transaction Management</li>
                <li> I have successfully develop CRM project with Microservices Architecture</li>
                <li> I used technologies like kafka, spring boot, feign, jwt, postgre etc.</li>
                
                
              </ul>
              <br></br>
              <p><strong>SOFTWARE ENGINEER INTERN | OCT 2023 - JAN 2024</strong></p>
              <p><strong>SIMERANYA - BILISIM VADISI</strong></p>
              <p> Fullstack Software Development with Java and Python Django.</p>
              <ul>
                <li>I created the company's blog page with Django.</li>
                <li>I have successfully created fully customizable and user friendly data miner with
                  Selenium and Java</li>
                <li> I gave a presentation and training on the use of Docker</li>
                <li> I have achieved successful backend development on one of the main application
                  called STAFF.</li>
              </ul>
              <br></br>
              <p><strong> SOFTWARE ENGINEER INTERN | JUL 2022 - OCT 2022</strong></p>
              <p>PAYE Online</p>
              <p>ERP development with Python ODOO</p>
              <ul>
                <li> I have successfully managed project database and make certain performance
                  optimization.</li>
                <li> I created multiple UI and managed them on the backend.</li>
              </ul>
            </Event>

            
          </Timeline>
        </Fragment>
      </div>

      


      <div class="skills">
        <Fragment>
          <Timeline>
            <Event class ="event" interval={"SKILLS"}>
              <ul>
                <li><h5>Java</h5></li>
                <li><h5>Spring Boot</h5></li>
                <li><h5>Docker</h5></li>
                <li><h5>Microservices</h5></li>
                <li><h5>Golang</h5></li>
                <li><h5>Node.js</h5></li>
                <li><h5>SQL</h5></li>
                <li><h5>Linux</h5></li>
                <li><h5>Python</h5></li>
              </ul>
            </Event>
          </Timeline>
        </Fragment>
      </div>

      <div class="lang">
        <Fragment>
          <Timeline>
            <Event interval={"LANGUAGE"}>
              <ul>
                <li><h5>English | Professional</h5></li>
                <li><h5>German | Beginner</h5></li>
              </ul>
            </Event>
          </Timeline>
        </Fragment>
      </div>
      <div class="courses">
        <Fragment>
          <Timeline>
          <Event interval={"COURSES"}>
              <ul>
                <li>
                  <h3>Turkcell</h3>
                  <p><strong>Geleceği Yazanlar Spring Boot Microservices Bootcamp | March 2024-Present</strong></p>
                </li>

                <li>
                  <h3>in28Minutes</h3>
                  <p><strong>Master Microservices with Spring Boot and Spring Cloud | Feb 2024</strong></p>
                </li>
                <li>
                  <h3>DefineX</h3>
                  <p><strong>Java Spring Bootcamp | March 2023</strong></p>
                </li>
                <li>
                  <h3>Akbank</h3>
                  <p><strong>Web 3.0 Solidity Practicum | Nov 2022</strong></p>
                </li>

                <li>
                  <h3>Siber Klüpler Birliği</h3>
                  <p><strong>Cyber Security Summer Camp 2020</strong></p>
                </li>

              </ul>
            </Event>
          </Timeline>
        </Fragment>

      </div>
    </div>

    

  );
}


export default About;
